<svg
  class="position-bottom-right"
  fill="none"
  height="570"
  viewBox="0 0 295 570"
  width="295"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M311.596 0.399892C272.341 10.7911 199.745 39.0869 165.276 98.6661C130.38 158.981 121.567 238.695 170.537 273.905C212.261 303.905 285.879 256.502 288.307 215.222C290.604 176.174 212.808 172.029 174.315 178.982C121.567 188.511 17.3208 240.946 3.39266 328.54C-6.13661 388.47 14.6804 474.646 88.786 497.304C166.229 520.982 307.983 365.072 231.648 354.442C180.913 347.376 132.13 378.781 118.486 439.203C105.375 497.263 131.302 626.446 280.452 729.549"
    stroke="#86BBD8"
    stroke-linecap="round"
    stroke-opacity="0.7"
    stroke-width="0.5"
  />
</svg>

<svg
  class="position-bottom-right"
  fill="none"
  height="291"
  viewBox="0 0 276 291"
  width="276"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle
    cx="269.5"
    cy="269.5"
    fill="url(#paint0_radial_2123_300)"
    opacity="0.2"
    r="269.5"
  />
  <defs>
    <radialGradient
      cx="0"
      cy="0"
      gradientTransform="translate(269.5 269.5) rotate(90) scale(269.5)"
      gradientUnits="userSpaceOnUse"
      id="paint0_radial_2123_300"
      r="1"
    >
      <stop stop-color="#F4B31D" />
      <stop offset="1" stop-color="#F4B31D" stop-opacity="0" />
    </radialGradient>
  </defs>
</svg>

<style>
  .position-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
  }
</style>
