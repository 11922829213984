<svg
  width="68"
  height="68"
  viewBox="0 0 89 89"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M0 44.5C0 69.0767 19.9233 89 44.5 89C69.0767 89 89 69.0767 89 44.5C89 19.9233 69.0767 0 44.5 0C19.9233 0 0 19.9233 0 44.5Z"
    fill="#B32929"
  />
  <rect
    x="30"
    y="54.4978"
    width="34.6451"
    height="5.77418"
    rx="2.88709"
    transform="rotate(-45 30 54.4978)"
    fill="white"
  />
  <rect
    x="54.498"
    y="58.5808"
    width="34.6451"
    height="5.77418"
    rx="2.88709"
    transform="rotate(-135 54.498 58.5808)"
    fill="white"
  />
</svg>
