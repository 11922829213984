<svg
  width="116"
  height="116"
  viewBox="0 0 116 116"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M0 58C0 90.0325 25.9675 116 58 116C90.0325 116 116 90.0325 116 58C116 25.9675 90.0325 0 58 0C25.9675 0 0 25.9675 0 58Z"
    fill="#f4b31d"
  />
  <path
    d="M80.7564 42.8913C80.0051 42.1338 78.9824 41.7078 77.9155 41.7078C76.8487 41.7078 75.826 42.1338 75.0747 42.8913L51.0679 66.8981L40.745 56.055C39.1761 54.4861 36.6324 54.4861 35.0634 56.055C33.4945 57.6239 33.4945 60.1677 35.0634 61.7366L48.4272 74.9003C49.9674 76.3485 52.3685 76.3485 53.9088 74.9003L80.5963 48.2128C81.9933 46.737 82.0621 44.4484 80.7564 42.8913Z"
    fill="white"
  />
</svg>
