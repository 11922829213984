<svg
  class="position-top-left"
  fill="none"
  height="439"
  viewBox="0 0 261 439"
  width="261"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M163.548 -51.0905C185.852 -31.0771 224.049 11.8994 226.686 62.6258C229.356 113.979 208.372 169.318 164.368 176.005C126.875 181.703 94.4245 125.828 106.695 97.8878C118.301 71.4586 170.812 94.8658 193.769 112.364C225.228 136.343 276.114 205.812 255.844 268.047C241.976 310.626 199.352 360.259 143.048 350.255C84.2086 339.802 43.4329 189.742 97.162 208.398C132.872 220.797 154.378 257.817 143.048 302.102C132.161 344.657 71.7345 420.832 -60.9003 438.481"
    stroke="#86BBD8"
    stroke-linecap="round"
    stroke-opacity="0.7"
    stroke-width="0.5"
  />
</svg>

<style>
  .position-top-left {
    position: absolute;
    top: 0;
    left: 0;
  }
</style>
