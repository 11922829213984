<script>
  import QRCode from "../QRJS.svelte";
  import { pollForNewPayment } from "../../shared/utils/Polling.svelte";
  import ToonieIcon from "../icons/ToonieIcon.svelte";
  import ErrorIcon from "../icons/ErrorIcon.svelte";
  import SuccessIcon from "../icons/SuccessIcon.svelte";

  export let isStreamModalVisible;
  export let loadDataError;
  export let streamPaymentData;
  export let qrCodeValue;
  export let onCloseModal;
  export let successUrl;
  export let errorUrl;
</script>

<div>
  <div class="pwt-modal {isStreamModalVisible ? 'pwt-modal--visible' : ''}">
    <div class="stream-modal-content">
      <button on:click={onCloseModal} class="pwt-modal__close-icon">X</button>
      {#if isStreamModalVisible}
        {#if loadDataError}
          <div class="pwt-modal__body">
            <div class="pwt-modal__title pwt-modal__title--error">Error</div>
            <div class="pwt-modal__icon">
              <ErrorIcon />
            </div>
            <div class="pwt-modal__message">
              There was an issue with loading data for this payment
            </div>
            <button on:click={onCloseModal} class="pwt-modal__btn">Close</button
            >
          </div>
        {/if}
        {#if !loadDataError && !qrCodeValue}
          <p>Loading...</p>
        {/if}
        {#if qrCodeValue && streamPaymentData && !loadDataError}
          {#await pollForNewPayment(streamPaymentData, "stream", successUrl, errorUrl)}
            <div class="pwt-modal__body">
              <QRCode codeValue={qrCodeValue} />
              <div class="pwt-modal__message">Scan QR-code with your app</div>
            </div>
          {:then}
            <div class="pwt-modal__body">
              <div class="pwt-modal__title">Done!</div>
              <div class="pwt-modal__icon">
                <SuccessIcon />
              </div>
              <div class="pwt-modal__message">Payment was successful!</div>
              <button on:click={onCloseModal} class="pwt-modal__btn"
                >Close</button
              >
            </div>
          {:catch error}
            <div class="pwt-modal__content pwt-modal__centered">
              <div class="pwt-modal__title pwt-modal__title--error">Error</div>
              <div class="pwt-modal__icon">
                <ErrorIcon />
              </div>
              <div class="pwt-modal__message">{error.message}</div>
              <button on:click={onCloseModal} class="pwt-modal__btn">
                Close
              </button>
            </div>
          {/await}
        {/if}
      {/if}
      <div class="pwt-modal__footer">
        <span>Powered by</span>
        <div class="footer-svg">
          <ToonieIcon width="70" height="15" viewBox="0 0 92 27" isSmall />
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @import "../../styles/modal.styles.scss";
</style>
