<svg
  width="25"
  height="25"
  viewBox="0 0 25 25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill="white"
    d="M17.5924 17.1659C19.2315 16.0514 20.4758 14.4278 21.1415 12.5351C21.8072 10.6424 21.8589 8.58115 21.2889 6.65615C20.7189 4.73115 19.5575 3.0446 17.9765 1.8459C16.3954 0.647202 14.4786 0 12.5096 0C10.5405 0 8.62372 0.647202 7.04265 1.8459C5.46159 3.0446 4.30019 4.73115 3.73019 6.65615C3.1602 8.58115 3.21187 10.6424 3.87757 12.5351C4.54327 14.4278 5.78765 16.0514 7.4267 17.1659C4.46182 18.282 1.92974 20.3473 0.208287 23.0537C0.117813 23.191 0.0549698 23.3454 0.0234101 23.5077C-0.0081495 23.6701 -0.00779616 23.8372 0.0244496 23.9995C0.0566954 24.1617 0.12019 24.3157 0.211244 24.4527C0.302297 24.5896 0.419093 24.7067 0.554842 24.7971C0.69059 24.8876 0.842584 24.9495 1.00199 24.9794C1.16139 25.0093 1.32503 25.0066 1.48338 24.9713C1.64173 24.9361 1.79165 24.869 1.92441 24.7741C2.05717 24.6792 2.17013 24.5582 2.25671 24.4183C3.36713 22.6739 4.88659 21.2405 6.6771 20.2482C8.46761 19.256 10.4724 18.7362 12.5096 18.7362C14.5467 18.7362 16.5515 19.256 18.342 20.2482C20.1325 21.2405 21.652 22.6739 22.7624 24.4183C22.9418 24.6906 23.2188 24.8798 23.5337 24.9451C23.8486 25.0104 24.176 24.9465 24.4452 24.7672C24.7143 24.5879 24.9035 24.3077 24.9719 23.987C25.0404 23.6664 24.9825 23.3311 24.8108 23.0537C23.0894 20.3473 20.5573 18.282 17.5924 17.1659ZM5.78337 9.37176C5.78337 8.01304 6.17786 6.68482 6.91694 5.55508C7.65602 4.42535 8.70651 3.54482 9.93556 3.02486C11.1646 2.5049 12.517 2.36885 13.8218 2.63393C15.1265 2.899 16.325 3.55329 17.2657 4.51405C18.2064 5.47482 18.847 6.69891 19.1065 8.03152C19.366 9.36414 19.2328 10.7454 18.7237 12.0007C18.2146 13.256 17.3525 14.329 16.2464 15.0838C15.1403 15.8387 13.8399 16.2416 12.5096 16.2416C10.7263 16.2395 9.01663 15.5151 7.75566 14.2272C6.4947 12.9393 5.7854 11.1931 5.78337 9.37176Z"
  />
</svg>
