<script>
  import ErrorIcon from "../icons/ErrorIcon.svelte";
  import ToonieIcon from "../icons/ToonieIcon.svelte";

  export let isErrorModalVisible;
  export let onCloseModal;
  export let error;
</script>

<div>
  <div class="pwt-modal {isErrorModalVisible ? 'pwt-modal--visible' : ''}">
    <div class="pwt-modal__content">
      <button on:click={onCloseModal} class="pwt-modal__close-icon">X</button>
      {#if isErrorModalVisible}
        <div class="pwt-modal__body">
          <div class="pwt-modal__icon">
            <ErrorIcon />
          </div>
          <div class="pwt-modal__title pwt-modal__title--error">Error</div>
          <div class="pwt-modal__message">
            {error}
          </div>
          <button on:click={onCloseModal} class="pwt-modal__btn">Close</button>
        </div>
      {/if}
      <div class="pwt-modal__footer">
        <span>Powered by</span>
        <div class="footer-svg">
          <ToonieIcon width="70" height="15" viewBox="0 0 92 27" isSmall />
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @import "../../styles/modal.styles.scss";
</style>
