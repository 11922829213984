<script context="module">
  import { paymentErrorsStore } from "../../store.js";

  export const Providers = {
    CARD: "STRIPE",
    PWT: "TOONIE",
    REGISTRATION: "CARD_REGISTRATION",
    STREAM: "CRYPTO",
  };

  // helper that validates options object and returns true if something is invalid
  export const checkInvalidOptions = (options) => {
    // options should be an object and should be not null
    if (typeof options !== "object" || options === null) {
      console.warn("options must be an object");
      return true;
    }

    // options.baseUrl should be a string
    if (options.baseUrl && typeof options.baseUrl !== "string") {
      console.warn("baseUrl must be a string");
      return true;
    }

    // options.createPaymentSession is required
    if (!options.createPaymentSession) {
      console.warn("createPaymentSession is required");
      return true;
    }

    // options.createPaymentSession should be a function type
    if (typeof options.createPaymentSession !== "function") {
      console.warn("createPaymentSession must be a function");
      return true;
    }

    // options.failurePaymentCallback is provided it should be a function
    if (
      options.failurePaymentCallback &&
      typeof options.failurePaymentCallback !== "function"
    ) {
      console.warn("failurePaymentCallback must be a function");
      return true;
    }

    // options.successPaymentCallback is provided it should be a function
    if (
      options.successPaymentCallback &&
      typeof options.successPaymentCallback !== "function"
    ) {
      console.warn("successPaymentCallback must be a function");
      return true;
    }

    // if options.genericErrorCallback is provided it should be a function
    if (
      options.genericErrorCallback &&
      typeof options.genericErrorCallback !== "function"
    ) {
      console.warn("genericErrorCallback must be a function");
      return true;
    }

    // if options.genericErrorCallback is provided it should be a function
    if (options.onModalClose && typeof options.onModalClose !== "function") {
      console.warn("onModalClose must be a function");
      return true;
    }

    return false;
  };

  export const formatAmountToDisplay = (amount, currency) => {
    if (!(amount && currency)) return "---";

    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  export const getFirstTwoLetters = (word) => {
    if (!word) return "";
    // Check if the word is a string and has at least two characters
    if (typeof word === "string" && word.length >= 2) {
      return word.substring(0, 2).toUpperCase();
    } else {
      // Handle cases where the word is too short or not a string
      return word.toUpperCase();
    }
  };

  export const calculateMaxBirthDateFromNow = () => {
    // Calculate the date 18 years ago from today
    const today = new Date();
    const year = today.getFullYear() - 18;
    const month = today.getMonth() + 1; // Months are zero-indexed, so add 1
    const day = today.getDate();

    // Format the date to YYYY-MM-DD for the input field
    return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
  };

  export const setError = (error) => {
    // setting it to null because if the same error is set twice, the second time it doesn't trigger the subscribers
    paymentErrorsStore.set(null);

    switch (error) {
      case "NO_PAYMENT_SESSION_ID":
        paymentErrorsStore.set("Missing Payment Session Id");
        break;
      case "NO_CARD_PAYMENT_PARAMS":
        paymentErrorsStore.set(
          "Error on loading payment data: missing clientSecret or paymentId",
        );
        break;
      case "NO_STREAM_PAYMENT_PARAMS":
        paymentErrorsStore.set(
          "Error on loading payment data: missing intentId",
        );
        break;
      case "STREAM_UNEXPECTED_ERROR":
        paymentErrorsStore.set(
          "There was an error loading data for this stream",
        );
        break;
      case "REGISTRATION_UNEXPECTED_ERROR":
        paymentErrorsStore.set(
          "There was an error loading data for this stream",
        );
        break;
      case "UNEXPECTED_ERROR":
        paymentErrorsStore.set("Unexpected error during the payment");
        break;
      case "PAYMENT_INTENT_NOT_FOUND":
        paymentErrorsStore.set("Session id not found in our system");
        break;
      default:
        paymentErrorsStore.set("Unexpected error");
        break;
    }
  };
</script>
