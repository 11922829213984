<script>
  import ToonieIcon from "../icons/ToonieIcon.svelte";
  import ErrorIcon from "../icons/ErrorIcon.svelte";
  import { get } from "svelte/store";
  import {
    cardPaymentModalStore,
    optionsStore,
    paymentDataStore,
  } from "../../store.js";
  import {
    calculateMaxBirthDateFromNow,
    Providers,
    setError,
  } from "../../shared/utils/utils.svelte";

  export let isRegistrationModalVisible;
  export let loadDataError;
  export let onCloseModal;
  export let sessionId;
  export let sessionCurrency;

  const options = get(optionsStore);
  const maxDate = calculateMaxBirthDateFromNow();

  let isPaymentInitiating = false;
  let isPaymentError = false;

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formDataValues = Object.fromEntries(formData.entries());

    isPaymentInitiating = true;

    const customerRegistrationData = await options.quickRegisterCustomer({
      email: formDataValues.email,
      currency: sessionCurrency,
      firstName: formDataValues.firstName,
      lastName: formDataValues.lastName,
      prefix: formDataValues.prefix,
      mobileNumber: formDataValues.phone,
      birthDate: formDataValues.dateOfBirth,
      paymentSessionId: sessionId,
    });

    if (customerRegistrationData) {
      const paymentData = await options.initiatePayment({
        paymentSessionId: sessionId,
        provider: Providers.REGISTRATION,
      });

      if (!paymentData) {
        setError("UNEXPECTED_ERROR");
        throw new Error(
          "Something went wrong during the payment initialization",
        );
      }

      isRegistrationModalVisible = false;

      cardPaymentModalStore.set(null);
      cardPaymentModalStore.set(true);

      paymentDataStore.set(null);
      paymentDataStore.set(paymentData);
      isPaymentInitiating = false;
    } else {
      isPaymentError = true;
      isPaymentInitiating = false;
      setError("REGISTRATION_UNEXPECTED_ERROR");
      throw new Error("Something went wrong during the registration");
    }
  };
</script>

<div>
  <div
    class="pwt-modal {isRegistrationModalVisible ? 'pwt-modal--visible' : ''}"
  >
    <div class="reg-modal__content">
      <button on:click={onCloseModal} class="pwt-modal__close-icon">X</button>
      {#if isRegistrationModalVisible}
        {#if loadDataError || isPaymentError}
          <div class="pwt-modal__body">
            <div class="pwt-modal__title pwt-modal__title--error">Error</div>
            <div class="pwt-modal__icon">
              <ErrorIcon />
            </div>
            <div class="pwt-modal__message">
              Something went wrong, please try again.
            </div>
            <button
              on:click={() => {
                isPaymentError = false;
                onCloseModal();
              }}
              class="pwt-modal__btn">Close</button
            >
          </div>
        {:else}
          <div class="reg-modal__title">Insert your details</div>
          <form on:submit={handleSubmit}>
            <div class="reg-modal__row">
              <div class="reg-modal__input-container">
                <label class="reg-modal__label" for="name">First name</label>
                <input
                  type="text"
                  id="name"
                  name="firstName"
                  placeholder="Your name"
                  required
                />
              </div>
              <div class="reg-modal__input-container">
                <label class="reg-modal__label" for="lastName">Last name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Your last name"
                  required
                />
              </div>
            </div>
            <div class="reg-modal__row">
              <div class="reg-modal__input-container">
                <label class="reg-modal__label" for="dateOfBirth"
                  >Date of birth</label
                >
                <input
                  type="date"
                  id="dateOfBirth"
                  name="dateOfBirth"
                  placeholder="dd/MM/yyyy"
                  max={maxDate}
                  value={maxDate}
                  required
                />
              </div>
              <div class="reg-modal__input-container">
                <label class="reg-modal__label" for="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your email"
                  required
                />
              </div>
            </div>
            <div class="reg-modal__row">
              <div
                class="reg-modal__input-container reg-modal__input-container--prefix"
              >
                <label class="reg-modal__label" for="prefix">Prefix</label>
                <input
                  type="tel"
                  id="prefix"
                  name="prefix"
                  placeholder="0000"
                  maxlength="5"
                  inputmode="numeric"
                  required
                />
              </div>
              <div class="reg-modal__input-container">
                <label class="reg-modal__label" for="phone">Mobile number</label
                >
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="Your phone number"
                  required
                />
              </div>
            </div>
            <div class="reg-modal__row">
              <div class="reg-modal__checkbox-container">
                <input type="checkbox" id="terms" name="terms" required />
                <label for="terms"
                  >By continuing, you agreeing to the <a
                    href={process.env.TERMS_AND_CONDITIONS_LINK}
                    target="_blank">terms of service</a
                  >
                  and the
                  <a href={process.env.PRIVACY_POLICY_LINK} target="_blank"
                    >privacy policy</a
                  > of Toonie</label
                >
              </div>
            </div>
            <div class="row">
              <button
                class="pwt-modal__btn"
                type="submit"
                disabled={isPaymentInitiating}
              >
                {#if isPaymentInitiating}
                  <div class="loader"></div>
                {:else}
                  Proceed
                {/if}
              </button>
            </div>
          </form>
        {/if}
      {/if}
      <div class="pwt-modal__footer">
        <span>Powered by</span>
        <div class="footer-svg">
          <ToonieIcon width="70" height="15" viewBox="0 0 92 27" isSmall />
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @import "../../styles/modal.styles.scss";
</style>
