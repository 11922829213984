<script></script>

<div class="container">
  <div class="card">
    <h2 class="title">Attention!</h2>
    <div class="description">This payment has already been completed.</div>
  </div>
</div>

<style>
  .container {
    font-family: var(--font-family), sans-serif;
    background-color: var(--secondary-white-color);
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20rem;
    min-height: 16rem;
    background-color: var(--main-white-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: var(--toonie-primary-color);
  }

  .title {
    font-size: 24px;
    font-weight: bold;
  }

  .description {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
  }
</style>
